<template>
    <div class="prestations">
        <router-link to="/Securisation" v-scroll-reveal="{ duration: 800, delay: 50, origin: 'bottom', distance: '500px'}">Portes blindées / Panneaux anti-intrusion</router-link>
        <router-link to="/Alarmes-Video-protection" v-scroll-reveal="{ duration: 800, delay: 100, origin: 'bottom', distance: '500px'}">Alarmes / Vidéo-protection / Télésurveillance</router-link>
        <router-link to="/Tolage-Murage" v-scroll-reveal="{ duration: 800, delay: 150, origin: 'bottom', distance: '500px'}">Tôlage / Murage / Bardage</router-link>
        <router-link to="/Nettoyage-Elagage" v-scroll-reveal="{ duration: 800, delay: 200, origin: 'bottom', distance: '500px'}">Nettoyage / Elagage / Démolition</router-link>
        <router-link to="/Gardiennage" v-scroll-reveal="{ duration: 800, delay: 200, origin: 'bottom', distance: '500px'}">Gardiennage</router-link>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
    .prestations {
        width: 100%;
        height: calc(100vh - 100px);
        display: flex;
        flex-wrap: wrap;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20%;
            height: 100%;
            color: #3c97f7;
            text-decoration: none;
            font-weight: bold;
            font-size: 20px;
            transition: all ease-in-out .2s;
            text-align: center;
            padding: 0 20px;

            &:nth-child(2n + 1) {
                background-color: #3c97f7;
                color: #fff;
            }
            @media screen and (min-width: 600px) {
                &:hover {
                    background-color: #6487ac;
                    color: #fff;
                }
            }
        }
    }
    @media screen and (max-width: 1160px) {
        .prestations {
            a {
                font-size: 15px;
            }
        }
    }
    @media screen and (max-width: 900px) {
        .prestations {
            a {
                width: 50%;
                height: 50%;
                font-size: 15px;

                &:nth-child(1) {
                    background-color: #3c97f7;
                    color: #fff;
                }
                &:nth-child(3) {
                    background-color: #fff;
                    color: #3c97f7;
                }
                &:nth-child(4) {
                    background-color: #3c97f7;
                    color: #fff;
                }
                @media screen and (min-width: 600px) {
                    &:hover {
                        background-color: #6487ac;
                        color: #fff;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 830px) {
        .prestations {
            height: calc(100vh - 80px);
        }
    }
    @media screen and (max-width: 600px) {
        .prestations {
            a {
                width: 100%;
    
            &:nth-child(1) {
                    background-color: #3c97f7;
                    color: #fff;
                }
                &:nth-child(4) {
                    background-color: #fff;
                    color: #3c97f7;
                }
                &:nth-child(3) {
                    background-color: #3c97f7;
                    color: #fff;
                }
            }    
        }
    }
</style> 

